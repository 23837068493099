<template>
	<div class="floor-layout tpl-5">
		<div class="main">
			<h2><a href="/goods?category=577&show_combo=1">全部扶贫商品</a>扶贫专场<span>爱心扶贫 助力甘肃-特色商品海惠严选</span></h2>
			<div class="fpList">
				<div v-for="(column, columnIndex) in data.columnList" :key="columnIndex" class="fpBox">
					<div class="bz-cover">
						<layout-item :block="column.blockList[0]" @handle-edit="handleEditBlock(columnIndex, 0)">
						</layout-item>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixin from '../mixin'

	export default {
		name: 'tpl-5', // 模块名称
		mixins: [mixin],
		title: '扶贫专场', // 模块标题
		dataTpl: { // 默认数据结构
			tpl_id: 5, // 模块ID
			tpl_type: 'ADV', // 模块类型，默认为ADV。（目前只支持ADV）
			columnList: [ // 数据列
				{
					titleColors: ['', ''],
					blockList: mixin.methods.emptyBlock(1, 'IMAGE')
				},
				{
					titleColors: ['', ''],
					blockList: mixin.methods.emptyBlock(1, 'IMAGE')
				},
				{
					titleColors: ['', ''],
					blockList: mixin.methods.emptyBlock(1, 'IMAGE')
				}
			]
		}
	}
</script>
<style scoped>
	.tpl-5{margin: 40px 0;}
	.main{padding: 24px;padding-bottom: 40px;padding-top: 0;background: #fff;}
	.main h2{line-height: 60px;font-size: 18px;color: #333333;font-weight: 600;margin: 0;}
	.main h2 a{float: right;}
	.main h2 a{display: block;width: 120px;height: 26px;line-height: 26px;text-align: center;background: #f42424;border-radius: 3px;font-size: 14px;color: #fff;font-weight: normal;margin-top: 17px;}
	.main h2 span{background: #4c4c4c;font-size: 12px;color: #fff;line-height: 22px;display: inline-block;padding: 0 10px;border-radius: 4px;font-weight: normal;position: relative;margin-left: 14px;top: -3px;}
	.main h2 span:before{border:5px solid rgba(0,0,0,0);display: block;content: '';position: absolute;border-right-color: #4c4c4c;left: -9px;top: 6px;}
	.fpList{overflow: hidden;}
	.fpBox{float: left;margin-right: 14px;}
	.fpBox:last-child{margin-right: 0;}
	.tpl-5 .bz-cover{width: 378px;height: 170px;margin: 0;float: none;}
	.bz-cover >>> .layout-item img{display: block;width: 100%;height: 100%;}
	.bz-cover >>> .layout-item:hover img{transform: translateX(0);}
</style>