<template>
	<div class="floor-layout tpl-6">
		<div class="fpList">
			<div v-for="(column, columnIndex) in data.columnList" :key="columnIndex" class="fpBox">
				<div class="bz-cover">
					<layout-item :block="column.blockList[0]" @handle-edit="handleEditBlock(columnIndex, 0)">
					</layout-item>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixin from '../mixin'

	export default {
		name: 'tpl-6', // 模块名称
		mixins: [mixin],
		title: '单行广告条', // 模块标题
		dataTpl: { // 默认数据结构
			tpl_id: 6, // 模块ID
			tpl_type: 'ADV', // 模块类型，默认为ADV。（目前只支持ADV）
			columnList: [ // 数据列
				{
					titleColors: ['', ''],
					blockList: mixin.methods.emptyBlock(1, 'IMAGE')
				}
			]
		}
	}
</script>
<style scoped>
	.tpl-6{margin: 20px 0;}
	.fpList{}
	.fpBox{}
	.bz-cover{width: 100%;height: auto;min-height:100px;margin: 0;float: none;background: none;}
	.bz-cover >>> .layout-item{min-height:100px;}
	.bz-cover >>> .no-image{min-height:100px;}
	.bz-cover >>> .layout-item img{display: block;width: 100%;min-height:100px;height: 100%;}
	.bz-cover >>> .layout-item:hover img{transform: translateX(0);}
</style>