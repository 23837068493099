<template>
	<div class="floor-layout tpl-7">
		<div v-for="(column, columnIndex) in data.columnList" :key="columnIndex" class="layout-main">
			<div class="layout-title">
				<template v-if="isEdit">
					<div class="edit-mask title" @click="handleEditTitle(columnIndex)">
            <el-button size="mini" type="primary" class="mask-btn-floor">
              <svg-icon icon-class="pen-leather"></svg-icon>编辑
            </el-button>
          </div>
          <div class="edit-mask tag" @click="handleEditTags(columnIndex)">
            <el-button size="mini"  type="primary" class="mask-btn-floor">
              <svg-icon icon-class="pen-leather"></svg-icon>编辑
            </el-button>
          </div>
				</template>
				<h3 class="layout-item bz-tit">{{ column.title }}</h3>
				<div class="bz-tags">
					<a
						v-for="(tag, index) in column.tagList"
						:key="index"
						:href="blockHref(tag)"
					>
						<span class="bz-tag-item">{{ tag.block_value }}</span>
					</a>
				</div>
			</div>
			<div class="layout-body" :style="colors(0, 180).title">
				<div class="coverBoxshow">
					<div class="bz-cover" v-for="(_block, blockIndex) in column.blockList">
						<layout-item
							:class="blockIndex == 0 ? 'first' : 'last'"
							:key="blockIndex"
							:block="_block"
							@handle-edit="handleEditBlock(columnIndex, blockIndex)"
							class="bz-mt-item">
							<template slot-scope="{ block }" v-if="blockIndex > 0">
								<p class="bz-co bz-co-tit">{{ block.block_opt.opt_title }}</p>
								<p class="bz-co bz-co-des">{{ block.block_opt.opt_desc }}</p>
							</template>
						</layout-item>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixin from '../mixin'
	export default {
		name: 'tpl-7',
		mixins: [mixin],
		title: '超值扶贫单品',
		dataTpl: {
			tpl_id: 7,
			tpl_type: 'ADV',
			columnList: [
				{
					title: '甘肃省',
					titleColors: ['#fb5149', '#fd3250'],
					tagList: mixin.methods.emptyBlock(3, 'TEXT'),
					blockList: mixin.methods.emptyBlock(10, 'IMAGE')
				}
			]
		}
	}
</script>
<style scoped>
	.tpl-7{margin: 20px 0;overflow: visible;padding-bottom: 50px;}
	.layout-title{background: none;padding: 0;padding: 20px 0;}
	.layout-title .edit-mask.title{width: 100px;}
	.layout-title .edit-mask.tag{left: 100px;}
	.layout-title .bz-tags{float: none;text-align: left;}
	.layout-title .bz-tit{font-size: 18px;color: #333333;font-weight: 600;line-height: 20px;height: auto;border-right: 3px solid #333;width: auto;padding-right: 20px;}
	.layout-title{height: 20px;}
	.layout-title .bz-tags{height: auto;padding-left: 8px;}
	.layout-title .bz-tag-item{font-size: 18px;color: #666;border:0;background: none;line-height: 20px;height: 20px;margin-left: 0;}
	.layout-body{border-radius: 12px;padding: 20px;height: 418px;padding-bottom: 0;overflow: visible;}
	.bz-cover{margin: 0;width: 234px;height: 232px;background: none;}
	.first{background: none;}
	.first >>> img{width: 204px;height: 205px;margin: auto;display: block;padding-top: 12px;}
	.last{background: #fff;position: relative;}
	.last >>> p{margin: 0;text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
	.last >>> img{width: 124px;height: 124px;position: absolute;left: 50%;margin-left: -62px;top: 20px;}
	.last >>> p.bz-co.bz-co-tit{padding-top: 144px;width: 160px;margin: auto;line-height: 40px;font-size: 18px;color: #333333;font-weight: 600;border-bottom: 1px solid #cfd8d5;}
	.last >>> p.bz-co.bz-co-des{width: 180px;margin: auto;line-height: 40px;font-size: 16px;color: #333333;}
	.bz-cover >>> .layout-item:hover img{transform: translateX(0);}
	.coverBoxshow{box-shadow: 0 10px 10px rgba(0,0,0,0.05);}
	.coverBoxshow:after{content: '';display: block;clear: both;}

</style>
